<app-page-header
  [titleText]="'Service Categories'"
  [hasAddButton]="true"
  (onAddClicked)="openPopup()"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="name" caption="Name"> </dxi-column>
  <dxi-column
    dataField="categoryId"
    caption="Category"
    [width]="180"
    [groupIndex]="1"
  >
    <dxo-lookup
      [dataSource]="categoryDataSource"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="oldPrice" caption="Old Price"> </dxi-column>
  <dxi-column dataField="price" caption="Price"> </dxi-column>

  <dxi-column
    dataField="status"
    caption="Status"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="280"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        class="mr-2"
        icon="edit"
        text="Edit"
        type="default"
        (onClick)="onEditClicked(data.value)"
        [width]="120"
      >
      </dx-button>

      <dx-button
        stylingMode="outlined"
        icon="trash"
        text="Delete"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="1000"
  [height]="'100%'"
  [showTitle]="true"
  title="Service"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Save',
      disabled: !isFormValid,
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Cancel',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Category*</div>
            <dx-select-box
              [dataSource]="categoryDataSource"
              [(value)]="item.categoryId"
              [searchEnabled]="true"
              valueExpr="id"
              stylingMode="outlined"
              displayExpr="name"
              searchExpr="name"
              [showClearButton]="true"
            >
            </dx-select-box>
          </div>

          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Service Name*</div>
            <dx-text-box
              stylingMode="outlined"
              [(value)]="item.name"
              [showClearButton]="true"
            ></dx-text-box>
          </div>

          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Price*</div>
            <dx-number-box
              stylingMode="outlined"
              [(value)]="item.price"
              [min]="0"
              [showClearButton]="true"
            ></dx-number-box>
          </div>

          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Old Price</div>
            <dx-number-box
              stylingMode="outlined"
              [(value)]="item.oldPrice"
              [min]="0"
              [showClearButton]="true"
            ></dx-number-box>
          </div>

          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Order</div>
            <dx-number-box
              stylingMode="outlined"
              [(value)]="item.order"
              [min]="0"
              [showClearButton]="true"
            ></dx-number-box>
          </div>


          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Image Required</div>
            <dx-check-box
              stylingMode="outlined"
              [(value)]="item.isImageRequired"
            ></dx-check-box>
          </div>

          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Status*</div>
            <dx-select-box
              [dataSource]="lookupStatusWithoutDelete"
              [(value)]="item.status"
              valueExpr="id"
              stylingMode="outlined"
              displayExpr="name"
              [showClearButton]="true"
            >
            </dx-select-box>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="row">
          <div class="col-12 mb-2 mb-md-3">
            <div class="label w-100">Information</div>

            <dx-html-editor
              [(value)]="item.content"
              [height]="'70vh'"
              [toolbar]="{
                items: [
                  'undo',
                  'redo',
                  'separator',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'separator',
                  'font',
                  'separator',
                  'color',
                  'background',
                  'separator',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'alignJustify',
                  'separator',
                  'orderedList',
                  'bulletList',
                  'separator',
                  'link',
                  'image',
                  'separator',
                  'blockquote',
                  'codeBlock',
                  'separator',
                  'clear',
                  'separator',
                  'insertTable',
                  'deleteTable',
                  'insertRowAbove',
                  'insertRowBelow',
                  'deleteRow',
                  'insertColumnLeft',
                  'insertColumnRight',
                  'deleteColumn'
                ]
              }"
            ></dx-html-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>
