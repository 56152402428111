import { BaseEntity } from "./BaseEntity";
import { Status } from "./status.enum";

export class ServiceCategoryDto {
  id: string | null;
  name: string;
  icon: string;
  order: number;
  status: Status;
}

export class Service extends BaseEntity {
  categoryId: string;
  name: string;
  content: string;
  order: number;

  //TODO: Temp without brand and car price. They will be removed when we switch to brand based pricing.
  price: number;
  oldPrice: number | null;
  isImageRequired: boolean = false;
}

export class Blog extends BaseEntity {
  /**Haber baslık verisidir. */
  title: string;

  /**Haber baslık verisidir. */
  seoTitle: string;

  /**Haber kısa icerik verisidir. */
  description: string;

  /**Haber kısa icerik verisidir. */
  seoDescription: string;

  /**Haber icerik verisidir. */
  content: string;

  /**Haber resim ismidir. */
  image: string;

  /**Sıra */
  order: number = 1;

  /**Sayfaya ait url bilgisidir */
  url: string;

  createdAt: Date | string = new Date();
}

export class Page extends BaseEntity {
  /**Sayfa başlığı */
  title: string;

  /**Sayfa başlığı */
  seoTitle: string;

  /**Sayfa bağlantısı */
  url: string;

  /**Açıklama SEO */
  description: string;

  /**Html içerik */
  content: string;
}

export class ServicePage extends Blog {
  icon: string;
  serviceParentId: string | null;
  serviceParent: Service | null;
}

export class Gallery extends BaseEntity {
  /**Resim bilgisidir. */
  title: string;

  /**Resim bilgisidir. */
  image: string;

  /**Sıralama bilgisidir. */
  order: number;
}

export class Testimonial extends BaseEntity {
  /**Referans adıdır Şirket adı vb. */
  name: string;

  /**Referansa ait kısa açıklama alanıdır */
  comment: string;

  /**Referansın var ise yönlendirme linkidir */
  url: string;

  /**Referansa ait logo vb. görsel isimlerinin tutulduğu alandır */
  image: string;
}

export class Faq {
  id: string;
  question: string;
  answer: string;
  order: number;
}

export enum ServiceStatusEnum {
  Pending = 0,
  InProgress = 1,
  Completed = 2,
}

export class ServiceRequest extends BaseEntity {
  title: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  postalCode: string;
  houseNumber: string;
  address: string;
  city: string;

  date: Date | string;
  time: Date | string;
  comments: string;
  systemComments: string;

  systemServiceStatus: ServiceStatusEnum;
  serviceStatusId: string | null;

  vehicle: VehicleBasicInfo;

  services: ServiceBasicInfo[];
  createdAt: Date;
  image: string;
  shownId: string | null;

  adverbsAccepted: boolean | null;
}

export class VehicleBasicInfo {
  brand: string;
  model: string;
  year: number;
  fuel: string;
  plate: string;
  motDate: string | null;
}

export class ServiceBasicInfo {
  id?: number;
  category: string;
  service: string;
  price: number;
}

export class Garage extends BaseEntity {
    logo: string;
    sex: string;
    firstname: string;
    lastname: string;
    city: string;
    address: string;
    company: string;
    taxNumber: string;
    employeeCount: number;
    totalCharge: number;
    subscriptionDuration: number;
    worksWeekend: boolean = false;
    notes: string;

    createdAt: Date | string;
}
