
export default class UrlUtils {
  static urlGenerator(value: string): string {
    return value
      .toLocaleLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove all non-word characters except spaces and hyphens
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace('’', '')
      .replace(/[ ]/g, "-")
      .replace(/[ğ]/g, "g")
      .replace(/[ş]/g, "s")
      .replace(/[ü]/g, "u")
      .replace(/[ç]/g, "c")
      .replace(/[ı]/g, "i")
      .replace(/[ö]/g, "o")
      .replace(/[.]/g, "")
      .replace(/[_]/g, "")
      .replace(/[~]/g, "")
      .replace(/[:]/g, "")
      .replace(/[/]/g, "")
      .replace(/[?]/g, "")
      .replace(/[#]/g, "")
      .replace(/[@]/g, "")
      .replace(/[!]/g, "")
      .replace(/[$]/g, "")
      .replace(/[&]/g, "")
      .replace(/[']/g, "")
      .replace(/["]/g, "")
      .replace(/[`]/g, "")
      .replace(/[(]/g, "")
      .replace(/[)]/g, "")
      .replace(/[*]/g, "")
      .replace(/[+]/g, "")
      .replace(/[,]/g, "")
      .replace(/[;]/g, "")
      .replace(/[=]/g, "")
      .replace(/[<]/g, "")
      .replace(/[>]/g, "")
      .replace(/[%]/g, "")
      .replace(/[{]/g, "")
      .replace(/[}]/g, "")
      .replace(/[|]/g, "")
      .replace(/[\^]/g, "")
      .replace(/[\[]/g, "")
      .replace(/[\]]/g, "")
      .replace(/['™']/g, "")
      .replace(/[\\]/g, "");
  }
}
