<div class="container-fluid mb-2">
  <dx-toolbar class="theme-dependent">
    <dxi-item location="before">
      <span class="toolbar-header">{{ titleText }}</span>
    </dxi-item>

    <dxi-item *ngIf="hasAddButton" location="after" locateInMenu="auto">
      <dx-button
        class="add-card"
        icon="plus"
        text="Add"
        type="default"
        (onClick)="onAddClick()"
      ></dx-button>
    </dxi-item>
  </dx-toolbar>
</div>
