import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Blog, Page } from 'src/app/shared/models/ServiceCategoryDto';
import StatusUtils, { Status } from 'src/app/shared/models/status.enum';
import UrlUtils from 'src/app/shared/models/UrlUtils';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-inner-pages-list',
  templateUrl: './inner-pages-list.component.html',
  styleUrls: ['./inner-pages-list.component.scss']
})
export class InnerPagesListComponent implements OnInit {

  dataSource: any[] = [];
  environment = environment;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  isPopupVisible: boolean;  
  item: Page = new Page();


  get isFormValid(): boolean {
    return (
      this.item.title && 
      this.item.title.length > 0 && 
      this.item.description != null && 
      this.item.description.length > 0 )
  }

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.httpService
      .get(`Page/Admin`)
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.router.navigateByUrl('/content/pages/' + id);
    // this.item = this.dataSource.find(x => x.id == id);
    // this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;

    this.httpService.put('Page/' + item.id, item).subscribe(res => {
      this.getList();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.item.id) {
      this.httpService.put('Page/' + this.item.id, this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    } else {
      this.item.status = Status.Passive;
      this.item.url = UrlUtils.urlGenerator(this.item.title);
      this.item.seoTitle = this.item.title;
      this.item.content = 'Draft content...';

      this.httpService.post('Page', this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    }
  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.item = new Blog();
  }.bind(this);


}
