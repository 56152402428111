import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Garage, Testimonial } from 'src/app/shared/models/ServiceCategoryDto';
import StatusUtils, { Status } from 'src/app/shared/models/status.enum';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-garages',
  templateUrl: './garages.component.html',
  styleUrls: ['./garages.component.scss']
})
export class GaragesComponent implements OnInit {

  
  dataSource: any[] = [];
  image: File[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  isPopupVisible: boolean;  
  item: Garage = new Garage();


  get isFormValid(): boolean {
    return (
      this.item.company && 
      this.item.company.length > 0 &&
      this.item.status != null)
  }

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
    this.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };  
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.httpService
      .get(`Garage`)
      .subscribe((res) => {
        this.dataSource =  res.map(x => {
          x.shownId = x.id.substring(0,6).toLocaleUpperCase();
          x.fullName = x?.sex + ' ' + x?.firstname + ' ' + x?.lastname;
          return x;
        });
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.item = {...this.dataSource.find(x => x.id == id)};
    this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;
    this.httpService.put('Garage/' + item.id, item).subscribe(res => {
      this.getList();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.item.id) {
      this.httpService.put('Garage/' + this.item.id, this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Garage', this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    }
  }.bind(this);

  imageUploaded(event) {
    if (event.request.response) {
      this.item.logo = JSON.parse(event.request.response)[0];
    } 
  }

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.item = new Garage();
  }.bind(this);
}
