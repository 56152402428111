import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  LoginFormComponent,
  ResetPasswordFormComponent,
  CreateAccountFormComponent,
  ChangePasswordFormComponent,
} from "./shared/components";
import { AuthGuardService } from "./shared/services";
import { HomeComponent } from "./pages/home/home.component";
import {
  DxAccordionModule,
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxHtmlEditorModule,
  DxListModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
} from "devextreme-angular";
import { DxDateRangeBoxModule } from "devextreme-angular";
import { PageHeaderComponent } from "./shared/components/page-header/page-header.component";
import { StatusBadgeComponent } from "./shared/components/status-badge/status-badge.component";
import { CommonModule } from "@angular/common";
import { ElapsedMinutesPipe } from "./shared/pipes/calculate-time.pipe";
import { ConvertUtcToLocalPipe } from "./shared/pipes/convert-utc-to-local.pipe";
import { ServiceCategoriesComponent } from "./pages/services/service-categories/service-categories.component";
import { ServiceListComponent } from "./pages/services/service-list/service-list.component";
import { ServiceStatusComponent } from "./pages/services/service-status/service-status.component";
import { HomeContentComponent } from "./pages/cms/home-content/home-content.component";
import { ServicePagesListComponent } from "./pages/cms/service-pages-list/service-pages-list.component";
import { ServicePageDetailsComponent } from "./pages/cms/service-page-details/service-page-details.component";
import { BlogListComponent } from "./pages/cms/blog-list/blog-list.component";
import { InnerPagesListComponent } from "./pages/cms/inner-pages-list/inner-pages-list.component";
import { InnerPageDetailsComponent } from "./pages/cms/inner-page-details/inner-page-details.component";
import { FaqListComponent } from "./pages/cms/faq-list/faq-list.component";
import { GalleryListComponent } from "./pages/cms/gallery-list/gallery-list.component";
import { TestimonialsComponent } from "./pages/cms/testimonials/testimonials.component";
import { CampaignsListComponent } from "./pages/cms/campaigns-list/campaigns-list.component";
import { CampaignDetailsComponent } from "./pages/cms/campaign-details/campaign-details.component";
import { ContactsComponent } from "./pages/contacts/contacts.component";
import { UsersComponent } from "./pages/users/users.component";
import { ServiceRequestsComponent } from "./pages/service-requests/service-requests.component";
import { BlogDetailComponent } from "./pages/cms/blog-detail/blog-detail.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GaragesComponent } from "./pages/garages/garages.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "services",
    canActivate: [AuthGuardService],
    children: [
      {
        path: "categories",
        component: ServiceCategoriesComponent,
      },
      {
        path: "list",
        component: ServiceListComponent,
      },
      {
        path: "status",
        component: ServiceStatusComponent,
      },
    ],
  },
  {
    path: "content",
    canActivate: [AuthGuardService],
    children: [
      {
        path: "home-page",
        component: HomeContentComponent,
      },
      {
        path: "service",
        component: ServicePagesListComponent,
      },
      {
        path: "service/:url",
        component: ServicePageDetailsComponent,
      },
      {
        path: "blog",
        component: BlogListComponent,
      },
      {
        path: "blog/:url",
        component: BlogDetailComponent,
      },
      {
        path: "pages",
        component: InnerPagesListComponent,
      },
      {
        path: "pages/:url",
        component: InnerPageDetailsComponent,
      },
      {
        path: "faq",
        component: FaqListComponent,
      },
      {
        path: "gallery",
        component: GalleryListComponent,
      },
      {
        path: "testimonials",
        component: TestimonialsComponent,
      },
      {
        path: "campaign",
        component: CampaignsListComponent,
      },
      {
        path: "campaign/:url",
        component: CampaignDetailsComponent,
      },
    ],
  },
  {
    path: "service-requests",
    component: ServiceRequestsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "garages",
    component: GaragesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "contact",
    component: ContactsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "users",
    component: UsersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "login-form",
    component: LoginFormComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  providers: [AuthGuardService, ConvertUtcToLocalPipe, ElapsedMinutesPipe],
  exports: [RouterModule],
  declarations: [
    HomeComponent,

    ServiceCategoriesComponent,
    ServiceListComponent,
    ServiceStatusComponent,
    BlogListComponent,
    BlogDetailComponent,
    FaqListComponent,
    GalleryListComponent,
    InnerPagesListComponent,
    InnerPageDetailsComponent,
    ServicePagesListComponent,
    ServicePageDetailsComponent,
    TestimonialsComponent,
    CampaignsListComponent,
    HomeContentComponent,
    CampaignDetailsComponent,
    ServiceRequestsComponent,
    ContactsComponent,
    UsersComponent,
    GaragesComponent,
    
    ElapsedMinutesPipe,
    ConvertUtcToLocalPipe,
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    CommonModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxFormModule,
    DxHtmlEditorModule,
    DxTooltipModule,
    DxTabsModule,
    DxScrollViewModule,
    DxDateBoxModule,
    DxTextBoxModule,
    DxRadioGroupModule,
    DxListModule,
    DxAccordionModule,
    DxCheckBoxModule,
    DxTabPanelModule,
    DxTextAreaModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxFileUploaderModule,
    DxDateRangeBoxModule,
    DxChartModule,
    DxPieChartModule,
    DxPopupModule,
    DxButtonModule,
    PageHeaderComponent,
    StatusBadgeComponent,
  ],
})
export class AppRoutingModule {}
