<div class="container-fluid mt-2">
  <div class="row mb-4">
    <div class="col-md-6 col-lg-4">
      <div class="dashboard-card">
        <h4 class="card-title">Total Services</h4>
        <div class="card-content">
          <div class="number">125</div>
          <div class="text">Services Completed</div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="dashboard-card">
        <h4 class="card-title">Pending Services</h4>
        <div class="card-content">
          <div class="number">32</div>
          <div class="text">Services Pending</div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <div class="dashboard-card">
        <h4 class="card-title">Completed Services</h4>
        <div class="card-content">
          <div class="number">88</div>
          <div class="text">Satisfied Customers</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-6">
      <div class="dashboard-section">
        <h4 class="section-title">Recent Activities</h4>
        <ul class="activity-list">
          <li>Service ID #456 completed for John Doe</li>
          <li>New appointment scheduled for 2024-08-07</li>
          <li>Service request from Jane Smith</li>
          <li>Service ID #123 pending approval</li>
        </ul>
      </div>
    </div>
    <div class="col-md-6">
      <div class="dashboard-section">
        <h4 class="section-title">Quick Links</h4>
        <div class="quick-links">
          <a routerLink="/service-requests" class="btn btn-primary">View Service Requests</a>
          <a routerLink="/services/list" class="btn btn-warning">Manage Services</a>
          <a routerLink="/content/blog"  class="btn btn-secondary">Add New Blog</a>

        </div>
      </div>
    </div>
  </div>
</div>
