import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { ItemCategoryRequestDto } from 'src/app/shared/models/item-cateogry.model';
import ProviderUtils from 'src/app/shared/models/provider.enum';
import { Blog, Page, ServiceCategoryDto, ServicePage } from 'src/app/shared/models/ServiceCategoryDto';
import StatusUtils, { Status } from 'src/app/shared/models/status.enum';
import UrlUtils from 'src/app/shared/models/UrlUtils';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-pages-list',
  templateUrl: './service-pages-list.component.html',
  styleUrls: ['./service-pages-list.component.scss']
})
export class ServicePagesListComponent implements OnInit {

  dataSource: any[] = [];
  image: File[] = [];
  icon: File[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  isPopupVisible: boolean;  
  item: ServicePage = new ServicePage();


  get isFormValid(): boolean {
    return (
      this.item.title && 
      this.item.title.length > 0 && 
      this.item.description != null && 
      this.item.description.length > 0 &&
      this.item.image != null &&
      this.item.icon != null);
  }

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
    this.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };  
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.httpService
      .get(`ServicePage/Admin`)
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.router.navigateByUrl('/content/service/' + id);
    // this.item = this.dataSource.find(x => x.id == id);
    // this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;

    this.httpService.put('ServicePage/' + item.id, {model: item}).subscribe(res => {
      this.getList();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.item.id) {
      this.httpService.put('ServicePage/' + this.item.id, {model: this.item}).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    } else {
      this.item.status = Status.Passive;
      this.item.url = UrlUtils.urlGenerator(this.item.title);
      this.item.seoTitle = this.item.title;
      this.item.seoDescription = this.item.description;
      this.item.content = 'Draft content...';

      this.httpService.post('ServicePage', {model: this.item}).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    }
  }.bind(this);

  imageUploaded(event) {
    if (event.request.response) {
      this.item.image = JSON.parse(event.request.response)[0];
    } 
  }

  iconUploaded(event) {
    if (event.request.response) {
      this.item.icon = JSON.parse(event.request.response)[0];
    } 
  }

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.item = new ServicePage();
  }.bind(this);

}
