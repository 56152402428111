<app-page-header
  [titleText]="'Contact Requests'"
  [hasAddButton]="false"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="name" caption="Name"> </dxi-column>
  <dxi-column dataField="surname" caption="Surname"> </dxi-column>
  <dxi-column dataField="subject" caption="Subject"> </dxi-column>
  <dxi-column dataField="email" caption="Email"> </dxi-column>
  <dxi-column dataField="phone" caption="Phone"> </dxi-column>
  <dxi-column dataField="message" caption="Message" [width]="600"> </dxi-column>
  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>
  <dxi-column
    dataField="status"
    caption="Status"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="deletedAt" caption="Created At" dataType="datetime" [sortOrder]="1" [sortOrder]="'desc'"> </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="280"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        class="mr-2"
        icon="edit"
        text="Edit"
        type="default"
        (onClick)="onEditClicked(data.data.id)"
        [width]="120"
      >
      </dx-button>

      <dx-button
        stylingMode="outlined"
        icon="trash"
        text="Delete"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Contact Request"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Save',
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Cancel',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Name*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.name"
          [readOnly]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Surname*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.surname"
          [readOnly]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Subject*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.subject"
          [readOnly]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Email</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.email"
          [readOnly]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Phone</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.phone"
          [readOnly]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Message*</div>
        <dx-text-area
          stylingMode="outlined"
          [(value)]="item.message"
          [height]="200"
        ></dx-text-area>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Status*</div>
        <dx-select-box
          [dataSource]="lookupStatusWithoutDelete"
          [(value)]="item.status"
          valueExpr="id"
          stylingMode="outlined"
          displayExpr="name"
          [showClearButton]="false"
        >
        </dx-select-box>
      </div>
    </div>
  </div>
</dx-popup>
