import { Component, OnInit } from "@angular/core";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import StatusUtils, { Status } from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from 'devextreme/ui/dialog';
import { ServiceCategoryDto } from "src/app/shared/models/ServiceCategoryDto";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-service-categories",
  templateUrl: `./service-categories.component.html`,
  styleUrls: ["./service-categories.component.scss"],
})
export class ServiceCategoriesComponent implements OnInit {
  dataSource: any[] = [];
  image: File[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;
  
  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;  
  itemCategory: ServiceCategoryDto = new ServiceCategoryDto();


  get isFormValid(): boolean {
    return this.itemCategory.name && this.itemCategory.name.length > 0 && this.itemCategory.status != null;
  }

  constructor(private httpService: HttpService) {
    this.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };  
  }

  ngOnInit(): void {
    this.getCaegories();
  }

  getCaegories() {
    this.httpService
      .get(`Service/AdminCategory`)
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.itemCategory = this.dataSource.find(x => x.id == id);
      this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;

    this.httpService.put('Service/Category/' + item.id, item).subscribe(res => {
      this.getCaegories();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.itemCategory.id) {
      this.httpService.put('Service/Category/' + this.itemCategory.id, this.itemCategory).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Service/Category', this.itemCategory).subscribe(res => {
        this.getCaegories();
        this.onCloseClicked();
      })
    }


  }.bind(this);

  imageUploaded(event) {
    if (event.request.response) {
      this.itemCategory.icon = JSON.parse(event.request.response)[0];
    } 
  }

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.itemCategory = new ItemCategoryRequestDto();
  }.bind(this);

}
