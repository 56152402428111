export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Services',
    icon: 'toolbox',
    items: [
      {
        text: 'Service Categories',
        path: '/services/categories'
      },
      {
        text: 'Service List',
        path: '/services/list'
      },
      // {
      //   text: 'Status List',
      //   path: '/services/status'
      // },
    ]
  },
  {
    text: 'Service Requests',
    icon: 'taskhelpneeded',
    path: '/service-requests'
  },
  {
    text: 'Content Management',
    icon: 'edittableheader',
    items: [
      {
        text: 'Blogs',
        path: '/content/blog'
      },
      {
        text: 'Service Pages',
        path: '/content/service'
      },
      {
        text: 'Pages',
        path: '/content/pages'
      },
      {
        text: 'F.A.Qs',
        path: '/content/faq'
      },
      {
        text: 'Gallery',
        path: '/content/gallery'
      },
      {
        text: 'Campaigns',
        path: '/content/campaign'
      },
      {
        text: 'Home Content',
        path: '/content/home-page'
      },
      {
        text: 'Testimonials',
        path: '/content/testimonials'
      }
    ]
  },
  {
    text: 'Contacts',
    path: '/contact',
    icon: 'ordersbox'
  },
  {
    text: 'Garages',
    path: '/garages',
    icon: 'car'
  }
];
