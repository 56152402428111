<app-page-header
  [titleText]="'Campaign List'"
  [hasAddButton]="true"
  (onAddClicked)="openPopup()"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column
    caption="#"
    [width]="80"
    dataField="image"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="imageTemplate"
  >
  </dxi-column>

  <dxi-column dataField="title" caption="Title"> </dxi-column>
  <dxi-column dataField="url" caption="URL"> </dxi-column>
  <dxi-column dataField="createdAt" caption="createdAt" dataType="date"> </dxi-column>

  <dxi-column
    dataField="status"
    caption="Status"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="140"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>

  <div *dxTemplate="let data of 'imageTemplate'">
    <img
      *ngIf="data.value"
      class="placeholder-img mx-2"
      [src]="environment.endpoint + '/file/' + data.value"
      alt=""
    />
  </div>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        class="mr-2"
        icon="edit"
        text="Edit"
        type="default"
        (onClick)="onEditClicked(data.data.url)"
        [width]="120"
      >
      </dx-button>

      <!-- <dx-button
        stylingMode="outlined"
        icon="trash"
        text="Delete"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button> -->
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Campaign"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Save',
      disabled: !isFormValid,
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Cancel',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12">
        <div class="label w-100">Image</div>
      </div>
      <div class="col-md-6 mb-2 mb-md-3">
        <dx-file-uploader
          #fileUploader
          [uploadUrl]="environment.endpoint + '/file'"
          [multiple]="false"
          accept="image/*"
          [(value)]="image"
          (onUploaded)="imageUploaded($event)"
          [uploadHeaders]="headers"
          uploadMode="instantly"
        ></dx-file-uploader>
      </div>
      <div class="col-md-6 mb-2 mb-md-3 d-flex justify-content-center">
        <img
          class="placeholder-img align-self-center"
          [src]="(environment + '/file/' + item?.image) ?? 'assets/placeholder.png'"
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Title*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.title"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Description*</div>
        <dx-text-area
        [height]="200"
          stylingMode="outlined"
          [(value)]="item.description"
        ></dx-text-area>
      </div>
    </div>
  </div>
</dx-popup>
