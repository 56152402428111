<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title">Page Details</h1>
      </div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="blogForm" class="blog-form">
      <div class="row">
        <div class="col-md-9 border-right">
          <div class="form-group">
            <label for="title">Title*</label>
            <dx-text-box
              formControlName="title"
              [showClearButton]="true"
              stylingMode="outlined"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label for="seoTitle">SEO Title*</label>
            <dx-text-box
              formControlName="seoTitle"
              [showClearButton]="true"
              stylingMode="outlined"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label for="description">Description*</label>
            <dx-text-area
              formControlName="description"
              [height]="80"
              stylingMode="outlined"
            ></dx-text-area>
          </div>
  
          <hr class="w-100" />
          <div class="form-group">
            <label for="content">Content*</label>
            <dx-html-editor
              formControlName="content"
              [height]="400"
              [toolbar]="{
                items: [
                  'undo',
                  'redo',
                  'separator',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'separator',
                  'font',
                  'separator',
                  'color',
                  'background',
                  'separator',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'alignJustify',
                  'separator',
                  'orderedList',
                  'bulletList',
                  'separator',
                  'link',
                  'image',
                  'separator',
                  'blockquote',
                  'codeBlock',
                  'separator',
                  'clear',
                  'separator',
                  'insertTable',
                  'deleteTable',
                  'insertRowAbove',
                  'insertRowBelow',
                  'deleteRow',
                  'insertColumnLeft',
                  'insertColumnRight',
                  'deleteColumn'
                ]
              }"
            ></dx-html-editor>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="status">Status*</label>
            <dx-select-box
              formControlName="status"
              [items]="statusOptions"
              displayExpr="name"
              valueExpr="id"
              stylingMode="outlined"
            ></dx-select-box>
          </div>
  
          <div class="form-group">
            <label for="image">SEO Preview</label>
  
            <div class="google-preview mt-0">
              <div class="preview-title">
                {{ blogForm.get("seoTitle")?.value || "Title" }}
              </div>
              <div class="preview-url">
                {{ baseUrl }}/{{ blogForm.get("url")?.value || "url" }}
              </div>
              <div class="preview-description">
                {{
                  blogForm.get("description")?.value ||
                    "Meta description will be displayed here."
                }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="url">URL</label>
            <dx-text-box
              formControlName="url"
              [readOnly]="true"
              [showClearButton]="true"
              stylingMode="outlined"
            ></dx-text-box>
          </div>

          <div class="d-flex justify-content-between">
            <div class="form-group">
              <dx-button
                stylingMode="outlined"
                icon="trash"
                text="Delete"
                (onClick)="onDeleteClicked()"
                type="danger"
              >
              </dx-button>
            </div>
  
            <div class="form-group text-right">
              <dx-button
                text="Save"
                type="success"
                [useSubmitBehavior]="true"
                [disabled]="!blogForm.valid"
              ></dx-button>
              <dx-button
                text="Cancel"
                type="normal"
                (onClick)="onCancel()"
              ></dx-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  