import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { ItemCategoryRequestDto } from 'src/app/shared/models/item-cateogry.model';
import ProviderUtils from 'src/app/shared/models/provider.enum';
import { Blog, Gallery, ServiceCategoryDto, Testimonial } from 'src/app/shared/models/ServiceCategoryDto';
import StatusUtils, { Status } from 'src/app/shared/models/status.enum';
import UrlUtils from 'src/app/shared/models/UrlUtils';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  dataSource: any[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  isPopupVisible: boolean;  
  item: any;

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.httpService
      .get(`Contact`)
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.item = this.dataSource.find(x => x.id == id);
    this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;
    this.httpService.put('Contact/' + item.id, item).subscribe(res => {
      this.getList();
    })
  }

  onSubmitClicked = function()  {    
    if (this.item.id) {
      this.httpService.put('Contact/' + this.item.id, this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Contact',this.item).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    }
  }.bind(this);

  onCloseClicked = function() {    
    this.isPopupVisible = false;
  }.bind(this);

}
