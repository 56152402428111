import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Blog } from "src/app/shared/models/ServiceCategoryDto";
import { Status } from "src/app/shared/models/status.enum";
import { HttpService } from "src/app/shared/services/http.service";
import { environment } from "src/environments/environment";
import { confirm } from 'devextreme/ui/dialog';
@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent implements OnInit {

  blogForm: FormGroup;
  environment = environment;
  statusOptions = [
    { id: 0, name: "Published - Active" },
    { id: 1, name: "Draft - Passive" },
  ];

  isPopupVisible = false;
  baseUrl: string = "https://fixfinder.nl";

  blogUrl: string;
  blog: any;
  headers: any;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router
  ) {
    this.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };  
    this.blogForm = this.fb.group({
      title: ["", Validators.required],
      status: [0, Validators.required],
      seoTitle: ["", Validators.required],
      description: ["", Validators.required],
      seoDescription: ["", Validators.required],
      content: ["", Validators.required],
      image: ["", Validators.required],
      order: [1, Validators.min(1)],
      url: ["", Validators.required],
      createdAt: [new Date(), Validators.required],
    });
  }

  ngOnInit() {
    this.blogUrl = this.activatedRoute.snapshot.params["url"];
    this.getBlog();
  }

  getBlog() {
    this.httpService
      .get(`Campaign/Admin/` + this.blogUrl)
      .subscribe((res) => {
        this.blog = res;
        this.blogForm.patchValue(res);
      });
  }

  onSubmit() {
    if (this.blogForm.valid) {
      const blogDetails: Blog = this.blogForm.value;
      this.httpService.put('Campaign/' + this.blog.id, {model: blogDetails}).subscribe(res => {
        this.onCancel();
      })
    }
  }


  async onDeleteClicked() {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    this.blog.status = Status.Deleted;
    this.httpService.put('Campaign/' + this.blog.id, {model: this.blog}).subscribe(res => {
      this.onCancel();
    })
  }

  onCancel() {
    this.router.navigateByUrl('/content/campaign')
  }

  imageUploaded(event: any) {
    this.blogForm.patchValue({ image: JSON.parse(event.request.response)[0]});
  }
}
