import { Component, OnInit } from '@angular/core';
import { ServiceRequest, ServiceStatusEnum } from 'src/app/shared/models/ServiceCategoryDto';
import { Status } from 'src/app/shared/models/status.enum';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss']
})
export class ServiceRequestsComponent implements OnInit {

  serviceRequests: ServiceRequest[] = [];

  selectedRequest: ServiceRequest | null = null;
  isPopupVisible = false;

  statuses = [
    { id: ServiceStatusEnum.Pending, name: 'Pending' },
    { id: ServiceStatusEnum.InProgress, name: 'In Progress' },
    { id: ServiceStatusEnum.Completed, name: 'Completed' }
  ];

  constructor(private httpService: HttpService) {
  }

  ngOnInit(): void {
    this.getServiceRequests();
  }

  getServiceRequests() {
    this.httpService.get('ServiceRequest').subscribe(res => {
      this.serviceRequests = res.map(x => {
        x.shownId = x.id.substring(0,6).toLocaleUpperCase();
        return x;
      });
    })
  }

  openPopup(request: ServiceRequest) {
    this.selectedRequest = { ...request };
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
    this.selectedRequest = null;
  }

  saveRequest() {
    if (this.selectedRequest) {
      this.httpService.put('ServiceRequest/' + this.selectedRequest.id, this.selectedRequest).subscribe(res => {
        this.getServiceRequests();
        this.closePopup();
      })
    }
  }

  onSubmitClicked() {
    this.saveRequest();
  }

  onCloseClicked() {
    this.closePopup();
  }

}
