import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { ItemCategoryRequestDto } from 'src/app/shared/models/item-cateogry.model';
import ProviderUtils from 'src/app/shared/models/provider.enum';
import { Blog, Gallery, ServiceCategoryDto, Testimonial } from 'src/app/shared/models/ServiceCategoryDto';
import StatusUtils, { Status } from 'src/app/shared/models/status.enum';
import UrlUtils from 'src/app/shared/models/UrlUtils';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {


  dataSource: any[] = [];
  image: File[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  isPopupVisible: boolean;  
  item: Testimonial = new Testimonial();


  get isFormValid(): boolean {
    return (
      this.item.name && 
      this.item.name.length > 0 && 
      this.item.status != null &&
      this.item.comment != null &&
      this.item.image != null);
  }

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
    this.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };  
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.httpService
      .get(`Testimonial/Admin`)
      .subscribe((res) => {
        this.dataSource = res;
      });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.item = this.dataSource.find(x => x.id == id);
    this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm("Are you sure you want to delete this item?", "Confirmation");
    if (!result) {
      return;
    }

    const item = this.dataSource.find(x => x.id == id);
    item.status = Status.Deleted;
    this.httpService.put('Testimonial/' + item.id, {model: item}).subscribe(res => {
      this.getList();
    })
  }

  onSubmitClicked = function()  {    
    if (!this.isFormValid ) {
      return;
    }

    if (this.item.id) {
      this.httpService.put('Testimonial/' + this.item.id, {model: this.item}).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    } else {
      this.httpService.post('Testimonial', {model: this.item}).subscribe(res => {
        this.getList();
        this.onCloseClicked();
      })
    }
  }.bind(this);

  imageUploaded(event) {
    if (event.request.response) {
      this.item.image = JSON.parse(event.request.response)[0];
    } 
  }

  onCloseClicked = function() {    
    this.isPopupVisible = false;
    this.item = new Testimonial();
  }.bind(this);

}
