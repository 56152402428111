<app-page-header
  [titleText]="'Service Requests'"
  [hasAddButton]="false"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="serviceRequests"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="shownId" caption="ID." width="90"></dxi-column>

  <dxi-column dataField="title" caption="Title" [width]="90"></dxi-column>
  <dxi-column dataField="name" caption="Name"></dxi-column>
  <dxi-column dataField="surname" caption="Surname"></dxi-column>
  <dxi-column dataField="email" caption="Email"></dxi-column>
  <dxi-column dataField="phoneNumber" caption="Phone Number"></dxi-column>
  <dxi-column dataField="adverbsAccepted" caption="Adverbs" dataType="boolean" [width]="40"></dxi-column>
  <dxi-column dataField="city" caption="City"></dxi-column>
  <dxi-column
    dataField="createdAt"
    caption="Created At"
    dataType="datetime"
    sortOrder="desc"
  ></dxi-column>
  <dxi-column
    dataField="date"
    caption="Service Date"
    dataType="date"
  ></dxi-column>
  <dxi-column dataField="systemServiceStatus" caption="Status" [width]="140">
    <dxo-lookup
      [dataSource]="statuses"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="vehicle.brand" caption="Brand"></dxi-column>
  <dxi-column dataField="vehicle.model" caption="Model"></dxi-column>
  <dxi-column dataField="vehicle.plate" caption="Plate"></dxi-column>
  <dxi-column dataField="vehicle.motDate" caption="MOT" dataType="date"></dxi-column>

  <dxi-column
    caption="#"
    [width]="140"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSorting]="false"
    cellTemplate="actionCellTemplate"
  ></dxi-column>

  <div *dxTemplate="let data of 'actionCellTemplate'">
    <div class="d-flex justify-content-end">
      <dx-button
        icon="edit"
        type="default"
        text="View"
        (onClick)="openPopup(data.data)"
      ></dx-button>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="900"
  [height]="'95vh'"
  [showTitle]="true"
  [title]="(selectedRequest?.shownId||'') +   ' - Service Request Details'"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="center" my="center" collision="fit"> </dxo-position>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div *ngIf="selectedRequest != null" class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label>Title</label>
            <dx-text-box
              [(value)]="selectedRequest.title"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Name</label>
            <dx-text-box
              [(value)]="selectedRequest.name"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Surname</label>
            <dx-text-box
              [(value)]="selectedRequest.surname"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Email</label>
            <dx-text-box
              [(value)]="selectedRequest.email"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Phone Number</label>
            <dx-text-box
              [(value)]="selectedRequest.phoneNumber"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>City</label>
            <dx-text-box
              [(value)]="selectedRequest.city"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Postal Code</label>
            <dx-text-box
              [(value)]="selectedRequest.postalCode"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Address</label>
            <dx-text-box
              [(value)]="selectedRequest.address"
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>House Number</label>
            <dx-text-box
              [(value)]="selectedRequest.houseNumber"
              [readOnly]="true"
            ></dx-text-box>
          </div>

          <div class="form-group">
            <label>Adverbs Accepted</label>
            <dx-check-box style="margin-left: 10px;"
              [(value)]="selectedRequest.adverbsAccepted"
              [readOnly]="true"
            ></dx-check-box>
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group">
            <label>Date and Time</label>
            <dx-text-box
              [value]="
                (selectedRequest.date | date) +
                ' From: ' +
                (selectedRequest.time ?? 'All Day')
              "
              [readOnly]="true"
            ></dx-text-box>
          </div>
          <div class="form-group">
            <label>Vehicle Information</label>
            <div class="card">
              <div class="card-body p-0">
                <table class="table">
                  <thead class="thead-dark">
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Year</th>
                    <th>Fuel</th>
                    <th>Plate</th>
                    <th>MOT</th>
                  </thead>
                  <tbody>
                    <td>
                      {{ selectedRequest.vehicle.brand }}
                    </td>
                    <td>
                      {{ selectedRequest.vehicle.model }}
                    </td>
                    <td>
                      {{ selectedRequest.vehicle.year }}
                    </td>
                    <td>
                      {{ selectedRequest.vehicle.fuel }}
                    </td>
                    <td>
                      {{ selectedRequest.vehicle.plate }}
                    </td>
                    <td>
                      {{ selectedRequest.vehicle?.motDate | date: 'MM-dd-yyyy' }}
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Service Information</label>
            <dx-data-grid
              [dataSource]="selectedRequest.services"
              [showBorders]="true"
            >
              <dxi-column dataField="category" caption="Category"></dxi-column>
              <dxi-column dataField="service" caption="Service"></dxi-column>
              <dxi-column dataField="price" caption="Price"></dxi-column>
            </dx-data-grid>
          </div>

          <div class="form-group" *ngIf="selectedRequest.image">
            <label>Image</label>
              <img [src]="selectedRequest.image" alt=""  style="width: 100%; max-height: 120px;"/>
          </div>

          <div class="form-group">
            <label>Comments</label>
            <dx-text-area
              [(value)]="selectedRequest.comments"
              [readOnly]="true"
            ></dx-text-area>
          </div>
          <div class="form-group">
            <label>System Comments</label>
            <dx-text-area
              stylingMode="outlined"
              [(value)]="selectedRequest.systemComments"
            ></dx-text-area>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-md-6">
              <div class="form-group">
                <label>Status</label>
                <dx-select-box
                  stylingMode="outlined"
                  [(value)]="selectedRequest.systemServiceStatus"
                  [items]="statuses"
                  displayExpr="name"
                  valueExpr="id"
                ></dx-select-box>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-center justify-content-end">
                <dx-button
                  class="mr-2"
                  text="Save"
                  type="success"
                  stylingMode="contained"
                  (onClick)="onSubmitClicked()"
                  [width]="120"
                >
                </dx-button>

                <dx-button
                  stylingMode="contained"
                  text="Cancel"
                  (onClick)="onCloseClicked()"
                  type="normal"
                  [width]="120"
                >
                </dx-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
