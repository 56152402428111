<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <!-- <dxi-item
      location="before"
      cssClass="header-title"
      *ngIf="title"
      [text]="title"
    >
    </dxi-item> -->

    <dxi-item
      location="before"
      cssClass="header-title"
      menuItemTemplate="menuItem"
    >
      <div *dxTemplate="let data of 'item'">
        <img src="assets/image.png" class="logo" alt="" />
      </div>
    </dxi-item>

    <!-- <dxi-item location="after" menuItemTemplate="themeSelector">
      <div *dxTemplate="let data of 'item'">
        <theme-selector></theme-selector>
      </div>
    </dxi-item> -->
    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <app-user-panel
          [user]="user"
          [menuItems]="userMenuItems"
          menuMode="context"
        ></app-user-panel>
      </div>
    </dxi-item>


    <dxi-item location="after" locateInMenu="auto">
      <div *dxTemplate="let data of 'item'">
        <dx-button [icon]="'runner'" [text]="'Logout'" (onClick)="authService.logOut()"></dx-button>
      </div>
    </dxi-item>


    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel
        [user]="user"
        [menuItems]="userMenuItems"
        menuMode="list"
      ></app-user-panel>
    </div>
  </dx-toolbar>
</header>
