<app-page-header
  [titleText]="'Garages'"
  [hasAddButton]="true"
  (onAddClicked)="openPopup()"
></app-page-header>

<dx-data-grid
  class="dx-card wide-card"
  [dataSource]="dataSource"
  keyExpr="id"
  [showColumnLines]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="true"
>
  <dxo-paging [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="shownId" caption="ID." width="90"></dxi-column>
  <dxi-column
    caption="#"
    [width]="80"
    dataField="logo"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="imageTemplate"
  >
  </dxi-column>

  <dxi-column dataField="company" caption="Company"> </dxi-column>
  <dxi-column dataField="fullName" caption="Full Name"> </dxi-column>
  <dxi-column dataField="city" caption="City"> </dxi-column>
  <dxi-column dataField="address" caption="Address"> </dxi-column>
  <dxi-column dataField="worksWeekend" caption="Weekend" dataType="boolean" width="80"> </dxi-column>

  <dxi-column dataField="createdAt" caption="Created At" dataType="date"> </dxi-column>
  <dxi-column dataField="notes" caption="Notes"> </dxi-column>


  <dxi-column dataField="taxNumber" caption="Tax No."> </dxi-column>
  <dxi-column dataField="employeeCount" caption="employeeCount"> </dxi-column>
  <dxi-column dataField="totalCharge" caption="Fee Received"> </dxi-column>
  <dxi-column dataField="subscriptionDuration" caption="Subscription D."> </dxi-column>

  <dxi-column
    dataField="status"
    caption="Status"
    [width]="180"
    cellTemplate="statusTemplate"
  >
    <dxo-lookup
      [dataSource]="lookupStatus"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="id"
    caption="#"
    [width]="280"
    [allowFiltering]="false"
    [allowHiding]="false"
    [allowSearch]="false"
    [allowSorting]="false"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>

  <div *dxTemplate="let data of 'statusTemplate'">
    <app-status-badge [status]="data.value"></app-status-badge>
  </div>

  <div *dxTemplate="let data of 'imageTemplate'">
    <img
      *ngIf="data.value"
      class="placeholder-img mx-2"
      [src]="environment.endpoint + '/file/' + data.value"
      alt=""
    />
  </div>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div class="d-flex align-items-center justify-content-end">
      <dx-button
        class="mr-2"
        icon="edit"
        text="Edit"
        type="default"
        (onClick)="onEditClicked(data.data.id)"
        [width]="120"
      >
      </dx-button>

      <dx-button
        stylingMode="outlined"
        icon="trash"
        text="Delete"
        (onClick)="onDeleteClicked(data.value)"
        type="danger"
        [width]="120"
      >
      </dx-button>
    </div>
  </div>
</dx-data-grid>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Garage"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="isPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Save',
      disabled: !isFormValid,
      onClick: onSubmitClicked
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Cancel',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12">
        <div class="label w-100">Logo</div>
      </div>
      <div class="col-md-6 mb-2 mb-md-3">
        <dx-file-uploader
          #fileUploader
          [uploadUrl]="environment.endpoint + '/file'"
          [multiple]="false"
          accept="image/*"
          [(value)]="image"
          (onUploaded)="imageUploaded($event)"
          [uploadHeaders]="headers"
          uploadMode="instantly"
        ></dx-file-uploader>
      </div>
      <div class="col-md-6 mb-2 mb-md-3 d-flex justify-content-center">
        <img
          class="placeholder-img align-self-center"
          [src]="
            environment.endpoint + '/file/' + item?.logo ?? 'assets/placeholder.png'
          "
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Company*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.company"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Tax Number</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.taxNumber"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">City</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.city"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Address</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.address"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Employee Count</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="item.employeeCount"
          [showClearButton]="true"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Fee Received</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="item.totalCharge"
          [showClearButton]="true"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Subscription Duration</div>
        <dx-number-box
          stylingMode="outlined"
          [(value)]="item.subscriptionDuration"
          [showClearButton]="true"
        ></dx-number-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Weekend</div>
        <dx-check-box style="margin-left: 10px;"
          stylingMode="outlined"
          [(value)]="item.worksWeekend"
        ></dx-check-box>
      </div>

      <hr>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Gender</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.sex"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">First Name</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.firstname"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Last Name</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="item.lastname"
          [showClearButton]="true"
        ></dx-text-box>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Notes</div>
        <dx-text-area
        stylingMode="outlined"
        [(value)]="item.notes"
      ></dx-text-area>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Status*</div>
        <dx-select-box
          [dataSource]="lookupStatusWithoutDelete"
          [(value)]="item.status"
          valueExpr="id"
          stylingMode="outlined"
          displayExpr="name"
          [showClearButton]="true"
        >
        </dx-select-box>
      </div>
    </div>
  </div>
</dx-popup>
