import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import StatusUtils, { Status } from "src/app/shared/models/status.enum";
import { confirm } from "devextreme/ui/dialog";
import { environment } from "src/environments/environment";
import { ItemCategoryRequestDto } from "src/app/shared/models/item-cateogry.model";
import ProviderUtils from "src/app/shared/models/provider.enum";
import {
  Service,
  ServiceCategoryDto,
} from "src/app/shared/models/ServiceCategoryDto";
import { HttpService } from "src/app/shared/services/http.service";

@Component({
  selector: "app-service-list",
  templateUrl: `./service.list.component.html`,
  styleUrls: ["./service-list.component.scss"],
})
export class ServiceListComponent {
  dataSource: any[] = [];
  categoryDataSource: any[] = [];
  image: File[] = [];
  environment = environment;
  headers: any;

  lookupStatus = StatusUtils.StatusNamesLookup;
  lookupStatusWithoutDelete = StatusUtils.StatusNamesWithoutDeletedLookup;

  providerNames = ProviderUtils.ProviderColumnNameDictionary;
  providerNamesArray = ProviderUtils.ProviderColumnNamesArray;

  isPopupVisible: boolean;
  item: Service = new Service();

  get isFormValid(): boolean {
    return (
      this.item.name &&
      this.item.name.length > 0 &&
      this.item.status != null &&
      this.item.price != null &&
      this.item.categoryId != null
    );
  }

  constructor(private httpService: HttpService) {
    this.headers = { Authorization: "Bearer " + localStorage.getItem("token") };
  }

  ngOnInit(): void {
    this.getCaegories();
    this.getServices();
  }

  getCaegories() {
    this.httpService.get(`Service/AdminCategory`).subscribe((res) => {
      this.categoryDataSource = res;
    });
  }

  getServices() {
    this.httpService.get(`Service/Admin`).subscribe((res) => {
      this.dataSource = res;
    });
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  onEditClicked(id: string) {
    this.item = this.dataSource.find((x) => x.id == id);
    this.openPopup();
  }

  async onDeleteClicked(id: string) {
    let result = await confirm(
      "Are you sure you want to delete this item?",
      "Confirmation"
    );
    if (!result) {
      return;
    }

    const item = this.dataSource.find((x) => x.id == id);
    item.status = Status.Deleted;

    this.httpService
      .put("Service/" + item.id, item)
      .subscribe((res) => {
        this.getServices();
      });
  }

  onSubmitClicked = function () {
    if (!this.isFormValid) {
      return;
    }

    if (this.item.id) {
      this.httpService
        .put("Service/" + this.item.id, this.item)
        .subscribe((res) => {
          this.getServices();
          this.onCloseClicked();
        });
    } else {
      this.httpService
        .post("Service", this.item)
        .subscribe((res) => {
          this.getServices();
          this.onCloseClicked();
        });
    }
  }.bind(this);

  onCloseClicked = function () {
    this.isPopupVisible = false;
    this.item = new Service();
  }.bind(this);
}
